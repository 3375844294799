.main-devider-roomType {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.room-type-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

/* RoomType tables Styles */
.table-roomTypeMV {
  padding: 0px 40px 32px 32px !important;
  margin-top: 32px;
  position: relative;
}
.table-roomTypeMV:after {
  content: '';
  height: 96%;
  min-height: 47vh;
  width: 1px;
  position: absolute;
  right: 0;
  top: 0;
  margin-bottom: 32px;
  background-color: RGBA(172, 180, 184, 0.2);
}
.table-suggestionsMV {
  padding: 32px 32px 32px 40px !important;
}

.roomType-tables {
  width: auto;
  min-height: 57vh;
  height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.dual-table-MV {
  display: flex;
  height: auto;
}
.roomType-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
  color: #434343;
}
.roomType-title {
  padding-bottom: 40px;
}
.suggestions-title {
  padding-bottom: 16px;
}
.suggestions-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #9b9b9b;
  padding-bottom: 24px;
  margin-left: 16px;
}

.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-RoomTypes {
  width: 100%;
}

.table-RoomTypes thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-RoomTypes tr th:not(:last-child),
.table-RoomTypes tr td:not(:last-child) {
  text-align: left;
}
.table-RoomTypes tr th:first-child,
.table-RoomTypes tr td:first-child {
  width: 215px;
  min-width: 180px;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-RoomTypes tr td:first-child {
  font-size: 14px !important;
}
.table-RoomTypes td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 12px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-RoomTypes tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-RoomTypes th {
  border-top: none !important;
  padding: 0px 17px 0px 17px;
}
.table-RoomTypes td,
.table-RoomTypes th {
  vertical-align: middle !important;
}

.table-RoomTypes td .edit-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #434343;
}
.table-RoomTypes td .delete-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #de6552;
}
.table-RoomTypes td .addRoomType-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.roomType-half-header {
  margin-bottom: 40px;
}
.table-roomTypeMV-half {
  padding: 0px 40px 32px 32px !important;
  margin-top: 32px;
}
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.roomTypeForm-wrap label {
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  padding-bottom: 4px;
  display: block;
}
.roomTypeForm-wrap input {
  max-width: auto;
  min-width: 180px;
  padding: 10px 0;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 32px;
}
.roomTypeForm-wrap input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}

/* Add Department Modal Style */
.editRoomType-form {
  padding: 40px;
}
.editRoomType-form .form-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.editRoomType-form .roomType-input-box {
  padding: 40px 0;
}
.editRoomType-form .roomType-input-box .roomType-input-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #bdbdbd;
  padding-bottom: 4px;
}
.editRoomType-form .roomType-input-box input {
  width: 380px;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  color: #434343;
}
.editRoomType-form .roomType-input-box input::placeholder {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.editRoom-submit {
  background: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  color: #ffffff;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.editRoom-cancle {
  background: #ffffff;
  color: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  margin-left: 24px;
}
