.efficiencyMV-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 29px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}

.efficiencyMV-form {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}

/* Table input style */
.HK-table-input {
  padding: 40px 32px;
}

.HK-table-input .HK-table-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
  padding-bottom: 16px;
}
.HK-table-input .HK-table-subTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #686868;
  margin-left: 16px;
}

.table-input-HK {
  margin-top: 40px;
}
.table-input-HK thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-input-HK tr th,
.table-input-HK tr td {
  width: 215px;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-input-HK tr td:first-child {
  font-size: 14px !important;
}
.table-input-HK td {
  font-weight: 500;
  padding: 12px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-input-HK tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-input-HK th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}

.table-input-HK tr td input {
  border: none;
  outline: none;
  background: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
}
.table-input-HK tr td input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.table-input-HK tr td input[type='number'] {
  -moz-appearance: textfield;
}
.table-input-HK tr td input::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
}

/* Other input style */
.efficiencyMV-input-wrap {
  padding: 0 32px 40px 32px;
  width: 400px;
}
.efficiencyMV-input-wrap .input-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
  padding-bottom: 16px;
}
.efficiencyMV-input-wrap .input-subTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #686868;
  margin-left: 16px;
  padding-bottom: 16px;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field {
  margin-left: 16px;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field label {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #bdbdbd;
  margin: 0;
  padding-bottom: 4px;
}
/* .efficiencyMV-input-wrap .efficiencyMV-input-field input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #434343;
  outline: none;
  background: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
  padding: 10px 0;
} */
.efficiencyMV-input-wrap
  .efficiencyMV-input-field
  input[type='number']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field input[type='number'] {
  -moz-appearance: textfield;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #434343;
  outline: none;
  background: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
  padding: 10px 0;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field input::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
}

.page-form-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0px 24px 40px 24px;
  padding: 0;
}

.efficiencyMV-input-wrap .input-title .toggle-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}

.efficiencyMV-form .efficiencyMV-save-btn {
  margin-left: 48px;
  padding-bottom: 32px;
}

.efficiencyMV-form .efficiencyMV-save-btn button {
  border: 1px solid #434343;
  background-color: #434343;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  padding: 13px 24px;
}

/* Wrapping Minutes */
.minutes-wrap {
  position: relative;
}
.suffix-minute {
  position: absolute;
  left: 0;
  top: 8px;
  color: #5bb69d;
  margin-left: 24px;
  font: inherit;
}
.table-suffix-minute {
  position: absolute;
  left: 0;
  top: 0;
  color: #5bb69d;
  margin-left: 24px;
  font: inherit;
}
