.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.checklistMV-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.checklistMV-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* Preventive Maintenance tables Styles */
.frontDesk-MV {
  padding: 32px 32px 0px 32px !important;
}
.maintenance-MV {
  padding: 18px 32px 0px 32px !important;
}
.housekeeping-MV {
  padding: 18px 32px 120px 32px !important;
}
.checklistMV-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.checklistMV-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-checklistMV {
  width: 100%;
}

.table-checklistMV thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-checklistMV tr th:not(:last-child),
.table-checklistMV tr td:not(:last-child) {
  text-align: left;
}
.table-checklistMV td,
.table-checklistMV th {
  vertical-align: middle !important;
}
.table-checklistMV tr th:first-child,
.table-checklistMV tr td:first-child {
  width: 445px;
  max-width: 445px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-checklistMV tr td:first-child {
  font-size: 14px !important;
}
.table-checklistMV td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-checklistMV tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-checklistMV tr td:first-child:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-checklistMV th {
  border-top: none !important;
  padding: 40px 17px 10px 17px;
}

.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.short-tables tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.checklistMV-details-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 40px 24px;
}

/* DetailsPage */
.btn-mark {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #bdbdbd;
  border: none;
}

.checklistMV-details .details-info {
  height: auto;
}

.checklistMV-details .details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin: 18px 16px;
}

.checklistMV-details .details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
}

.checklistMV-details .details-filters .work-day-info,
.checklistMV-details .details-filters .assigned-info,
.checklistMV-details .details-filters .reminder-info,
.checklistMV-details .details-filters .shift-info {
  display: flex;
  align-items: center;
  min-width: 125px;
  padding-right: 24px;
}
.checklistMV-details .details-filters .work-day-info p,
.checklistMV-details .details-filters .assigned-info p,
.checklistMV-details .details-filters .reminder-info p,
.checklistMV-details .details-filters .shift-info p {
  font-size: 14px;
}

.checklistMV-details .details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 30px 16px;
}

.addExpense .addExpense-btn {
  border: none;
  outline: none;
  background: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}
.addExpense .addExpense-btn:hover {
  cursor: pointer;
}
.addExpense .addExpense-text {
  font-size: 13px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-left: 16px;
}

/* Drawer Styles */
.drawer-wraper {
  height: auto;
  margin: 0 auto;
}
.checklistMV-form-devider {
  margin: 40px auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}
.checklistMV-add-department {
  margin: auto 16px;
}
.checklistMV-add-items {
  margin: auto 16px;
}
.checklistMV-add-department .form-dept-head {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-bottom: 40px;
}

.checklistMV-add-items .form-items-head {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-bottom: 32px;
}
.checklistMV-input-label {
  color: #bdbdbd;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 4px;
}

.checklistMV-add-items .input-item {
  margin-bottom: 24px;
}

.checklistMV-add-items input {
  width: 100%;
  padding: 10px 0px;
  outline: none;
  background: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #434343;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.checklistMV-add-items .add-items-task input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.checklistMV-add-items .add-item-desc textarea {
  width: 100%;
  padding: 10px 0px;
  outline: none;
  background: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #434343;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  resize: none;
}
.checklistMV-add-items .add-item-desc textarea::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}

.input-item-dropdown {
  margin-bottom: 24px;
  width: 200px;
}

.drawer-btns {
  width: 100%;
  margin: 40px auto;
  display: flex;
}
.drawer-btns .drawer-cancle {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  margin-right: 32px;
}
.drawer-btns .drawer-save {
  border: 1px solid #434343;
  border-radius: 4px;
  outline: none;
  padding: 13px 24px;
  background: #434343;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
}

.checklistMV-checkbox {
  -webkit-appearance: initial;
  appearance: initial;
  width: 15px;
  height: 15px;
  border: none;
  background: rgb(255, 255, 255);
  position: relative;
  border: 2px solid #3b3b3b;
}
.checklistMV-checkbox:checked {
  background: rgb(255, 255, 255);
}
.checklistMV-checkbox:checked:after {
  content: '\25FC';
  color: #3b3b3b;
  position: absolute;
  left: 44%;
  top: 50%;
  -webkit-transform: translate(-50%, -54%);
  -moz-transform: translate(-50%, -54%);
  -ms-transform: translate(-50%, -54%);
  transform: translate(-50%, -54%);
}

.checklistMV-checkbox:hover {
  cursor: pointer;
}
