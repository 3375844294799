@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;700;800&display=swap');
.sign-up {
  width: 1100px;
  height: 800px;
  background-color: #ffffff;
  margin: 60px auto;
  box-shadow: 0 15px 30px -10px rgb(172 180 184 / 20%) !important;
}
.signUp-img-section {
  position: relative;
  height: 100%;
  width: 473px;
  background: #3b3b3b;
  padding: 40px 32px;
}
.signUp-img-section .app-text {
  width: 258px;
}
.signUp-img-section .app-text .brand-title {
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}
.signUp-img-section .app-text .brand-subTitle {
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding-top: 32px;
}

.signUp-img-section img {
  width: 318px;
  height: 346px;
  display: flex;
  margin: 100px auto;
}

/* SignUp form style */
.signUp-form-section {
  width: 380px;
  margin: 100px auto;
}
.signUp-form-title {
  font-family: 'Open sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #434343;
  padding-bottom: 48px;
}

.signUp-input-box {
  padding-bottom: 18px;
}
.signUp-input-box label {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #434343;
  margin-bottom: 3px;
  display: block;
}
.signUp-input-box input[type='text'],
.signUp-input-box input[type='email'],
.signUp-input-box input[type='password'] {
  width: 100%;
  padding: 10px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  outline: none;
  background: none;
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #434343;
}
.signUp-input-box input::placeholder {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(175, 175, 180);
}

/* Checkbox style */
.signUp-check-box label {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #434343;
  margin-bottom: 0;
  padding-left: 16px;
}
.signUp-check-box label:hover {
  cursor: pointer !important;
}
.signUp-check-box input[type='checkbox']:checked {
  background: rgb(255, 255, 255);
}
.signUp-check-box input[type='checkbox']:checked:after {
  content: '\25FC';
  color: rgb(0, 0, 0);
  position: absolute;
  left: 50% !important;
  top: 45% !important;
  -webkit-transform: translate(-48%, -52%) !important;
  -moz-transform: translate(-48%, -52%) !important;
  -ms-transform: translate(-48%, -52%) !important;
  transform: translate(-48%, -52%) !important;
}

.signUp-input-box .error-msg {
  display: block;
  color: #de6552;
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
}

/* SignUp button style */
.signUp-btn {
  width: 100%;
  padding: 12px 130px;
  background-color: #434343;
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border: 1px solid #434343;
  outline: none;
  margin: 32px auto;
}

/* Password popUp style */
.pass-check {
  width: 200px;
  height: 250;
  padding: 32px 24px;
}

.pass-msg p {
  font-family: 'Open sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 16px;
}
.pass-msg {
  margin-bottom: 8px;
}

.signUp-success {
  width: 1100px;
  height: 800px;
  background-color: #ffffff;
  margin: 60px auto;
  box-shadow: 0 15px 30px -10px rgb(172 180 184 / 20%) !important;
  text-align: center;
}
.signUp-success img {
  height: 345px;
  margin-top: 74px;
}
.signUp-success .success-title {
  color: #434343;
  font-family: 'Open sans', sans-serif;
  font-size: 40px;
  font-weight: 700;
  padding-top: 48px;
  padding-bottom: 20px;
}

.signUp-success .success-text {
  color: #434343;
  font-family: 'Open sans', sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.signUp-success .start-btn {
  padding: 12px 130px;
  background-color: #434343;
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border: 1px solid #434343;
  outline: none;
  margin: 48px auto;
}

/* Login Page */
.forgot-text {
  font-family: 'Open sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #434343;
  padding-top: 40px;
}

.hotel-info {
  width: auto;
  position: absolute;
  right: 32px;
  bottom: 40px;
  text-align: right;
}

.hotel-info .hotel-text {
  font-family: 'Open sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding-bottom: 6px;
}
.hotel-info .hotel-name {
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}
