.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.requests-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.requests-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* requests tables Styles */
.frontDesk-requests {
  padding: 32px 32px 0px 32px !important;
}
.maintenance-requests {
  padding: 18px 32px 120px 32px !important;
}
.requests-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.requests-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-requests {
  width: 100%;
  /* table-layout: fixed;
  word-wrap: break-word; */
}

.table-requests thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-requests tr th:not(:last-child),
.table-requests tr td:not(:last-child) {
  text-align: left;
}
.table-requests tr th:first-child,
.table-requests tr td:first-child {
  width: 378px;
  max-width: 378px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-requests tr td:first-child {
  font-size: 14px !important;
}
.table-requests td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 6px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-requests tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-requests tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-requests th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-requests td,
.table-requests th {
  vertical-align: middle !important;
}

.table-requests .td-status p {
  padding: 8px 12px !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: fit-content;
}

.table-requests td button {
  padding: 13px 0px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: 126px;
}
.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.short-tables tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.request-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.request-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

#requests-title-field {
  width: 100%;
  font-size: 24px;
  border: none;
  margin-bottom: 16px;
}
#requests-title-field:focus-visible {
  width: 100%;
  font-size: 24px;
  border: none;
  outline: none;
}
#requests-room-field {
  width: 100%;
  font-size: 20px;
  border: none;
  margin: 16px auto;
}
#requests-room-field:focus-visible {
  width: 100%;
  font-size: 20px;
  border: none;
  outline: none;
}
#requests-details-field {
  width: 100%;
  font-size: 16px;
  border: none;
  margin-top: 13px;
}
#requests-details-field:focus-visible {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}
.select-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.select-menus .due-date,
.select-menus .priority,
.select-menus .assigned,
.select-menus .department {
  width: auto;
}
.form-toggle-label {
  font-size: 14px;
}

/* Datepicker Plugin Style */
.datepicker-footer-plugin {
  padding: 16px 24px;
}
.footer-plugin-devider {
  margin: 0px 8px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  padding: 0;
}

.start-date-box,
.end-date-box {
  height: 30px;
  width: 100px;
  border: 1px solid #e9e9e9;
}
.datepicker-footer-plugin .start-date-title,
.datepicker-footer-plugin .end-date-title {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.start-date-box span,
.end-date-box span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  display: flex;
  padding: 4px 0 0 6px;
}

/* DetailsPage */
.btn-mark {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #bdbdbd;
  border: none;
}

.details-info {
  height: auto;
}

.details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 16px;
}

.details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 8px;
}
.details-filters .start-date-info,
.details-filters .due-date-info,
.details-filters .assigned-info,
.details-filters .priority-info {
  display: flex;
  align-items: center;
  padding-right: 24px;
}
.details-filters .start-date-info p,
.details-filters .due-date-info p,
.details-filters .assigned-info p,
.details-filters .priority-info p {
  font-size: 14px;
}

.details-status p {
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
}
.details-info .detailsRoom {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-top: 16px;
  padding-bottom: 16px;
}

.details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  margin-top: 13px;
  padding-top: 27px;
  padding-bottom: 27px;
}
