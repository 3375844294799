.hk-overview {
  height: auto;
}
.hk-overview-statistics {
  width: max-content;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: none !important;
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0.25rem;
}
.hk-overview-card {
  padding: 0 !important;
}
.hk-overview-cardBody {
  padding: 0 32px !important;
}

.hk-overview-card img {
  height: 24px;
  margin-top: 22px;
}
.hk-overview-card .value {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #434343;
  font-weight: 600;
}
.hk-overview-card .state-name {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #bdbdbd;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.hk-overview-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 30px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.hk-overview-head .filters {
  display: flex;
  align-items: center;
}

/* Housekeeping table Styles */
.hk-overview-table {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.hk-overview-table-wrap {
  padding: 32px !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-hk-overview {
  width: 100%;
}

.table-hk-overview thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-hk-overview tr th:first-child,
.table-hk-overview tr td:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-hk-overview tr td:first-child {
  font-size: 14px !important;
}
.table-hk-overview td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 10px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-hk-overview tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
/* .table-hk-overview tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
} */
.table-hk-overview th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-hk-overview td,
.table-hk-overview th {
  vertical-align: middle !important;
}

.table-hk-overview .td-status p {
  padding: 8px 12px !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: fit-content;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}
