@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;700;800&display=swap);
body {
  background: #fafafa !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Open Sans', sans-serif;
}
.pages {
  height: auto;
  width: 100%;
  margin-left: 8.5rem !important;
  background: #fafafa !important;
}
.main-section {
  margin: 40px;
}

hr {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* React Datepicker Styles */
.react-datepicker {
  border: 1px solid #e9e9e9 !important;
}
.react-datepicker__month-container {
  float: unset !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #e9e9e9 !important;
}
.react-datepicker__input-container input {
  margin-left: 8px;
  margin-right: 12px;
  width: 94px;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 14px;
}
.react-datepicker__input-container input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #161616;
}
.react-datepicker__input-container input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #161616;
}
.react-datepicker__input-container input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #161616;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.1rem !important;
  background-color: #bdbdbd !important;
  color: #000000 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.1rem !important;
  background-color: #3b3b3b !important;
  color: #fff;
}

.react-datepicker__year-text--in-selecting-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.1rem !important;
  background-color: #3b3b3b !important;
  color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--selected {
  border-radius: 0.1rem !important;
  background-color: #3b3b3b !important;
  color: #fff !important;
}

/* dropDown Check style */

.dropDown-check {
  -webkit-appearance: initial;
  appearance: initial;
  width: 15px;
  height: 15px;
  border: none;
  background: rgb(255, 255, 255);
  position: relative;
  border: 2px solid #3b3b3b;
}
.dropDown-check:checked {
  background: rgb(255, 255, 255);
}
.dropDown-check:checked:after {
  content: '\25FC';
  color: #3b3b3b;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -56%);
  transform: translate(-50%, -56%);
}

.dropDown-check:hover {
  cursor: pointer;
}

.sidebar {
  width: 145px;
  height: 100vh;
  position: fixed;
  z-index: 1;
  background: #fafafa;
}
.sidebar ul {
  margin-top: 2.5rem;
  list-style: none;
}
.sidebar li {
  margin: 1rem;
}

.brand-name {
  position: absolute;
  left: 49%;
  right: auto;
  bottom: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0 auto;
  font-family: 'Alegreya Sans SC', sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #d3d3d3;
}
.setting {
  width: 100%;
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 35px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0 auto;
}

.overview {
  height: auto;
}

.overview-title {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 24px 32px !important;
  margin-bottom: 24px !important;
}
.overview-title p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.statistic {
  width: 100%;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  padding: 0.5rem !important;
  border: none !important;
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0.25rem;
}

.statistic-card img {
  height: 24px;
}
.statistic-card .value {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #434343;
  font-weight: 600;
}
.statistic-card .state-name {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #bdbdbd;
}

/* Table styles */
.comparison-section,
.our-rate-section,
.we-offer-section,
.onLeave-section,
.event-section {
  padding: 24px 32px;
}
.table-top {
  padding-bottom: 24px;
  margin: 0 12px;
}

.event-section,
.onLeave-section,
.comparison-section {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.our-rate-section,
.we-offer-section {
  width: 100%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.dash-table-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table {
  width: 100%;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}
.table td {
  font-size: 14px !important;
  font-weight: 500;
}

.table th {
  border-top: none !important;
}
.table td {
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table td,
.table th {
  padding: 0.75 0.75rem !important;
  vertical-align: top;
}
.table td .other-price {
  padding-right: 0.15rem;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.announcements-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.announcements-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}
.announcments .filters {
  display: flex;
  align-items: center;
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* Announcement tables Styles */
.latest-announcement {
  padding: 32px 32px 0px 32px !important;
}
.past-announcement {
  padding: 18px 32px 120px 32px !important;
}
.announcement-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.announcement-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-announcement {
  width: 100%;
  /* table-layout: fixed;
  word-wrap: break-word; */
}

.table-announcement thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-announcement tr th:nth-child(2),
.table-announcement tr th:nth-child(3),
.table-announcement tr th:nth-child(4),
.table-announcement tr td:nth-child(2),
.table-announcement tr td:nth-child(3),
.table-announcement tr td:nth-child(4) {
  text-align: left;
}
.table-announcement tr th:first-child,
.table-announcement tr td:first-child {
  width: 480px;
  max-width: 480px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-announcement tr td:first-child {
  font-size: 14px !important;
}
.table-announcement td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-announcement tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-announcement tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-announcement th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-announcement td,
.table-announcement th {
  vertical-align: middle !important;
}

.table-announcement td button {
  padding: 13px 30px;
  background: #434343;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.short-tables tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.announcement-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.announcement-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

#announcement-title-field {
  width: 100%;
  font-size: 24px;
  border: none;
  margin-bottom: 16px;
}
#announcement-title-field:focus-visible {
  width: 100%;
  font-size: 24px;
  border: none;
  outline: none;
}
#announcement-details-field {
  width: 100%;
  font-size: 16px;
  border: none;
  margin-top: 13px;
}
#announcement-details-field:focus-visible {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}

.select-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.select-menus .due-date,
.select-menus .priority,
.select-menus .assigned,
.select-menus .department {
  width: auto;
}

.form-toggle-label {
  font-size: 14px;
}

/* DetailsPage */
.details-info {
  height: auto;
}

.details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 16px;
}

.details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.details-filters .due-date-info,
.details-filters .department-info,
.details-filters .priority-info {
  display: flex;
  align-items: center;
  padding-right: 24px;
}
.details-filters .due-date-info p,
.details-filters .department-info p,
.details-filters .priority-info p {
  font-size: 14px;
}

.details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  margin-top: 13px;
  padding-top: 27px;
  padding-bottom: 27px;
}

/* Sign and Close */
#sign-box {
  width: 40%;
  text-align: center;
  border: 1px solid #acb4b8;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.sign-title {
  color: #434343;
  font-size: 24px !important;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding-top: 80px;
}
.sign-here {
  color: #434343;
  font-size: 16px !important;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding-top: 22px;
}
#sign-box {
  padding-top: 18px;
  padding-bottom: 18px;
  margin-top: 24px;
  margin-bottom: 40px;
  width: 420px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
}

.btn-confirm,
.btn-readAgain {
  padding: 13px 24px;
  border: 2px solid #434343;
  border-radius: 3px;
}
.btn-confirm {
  background: #434343;
  color: #ffffff;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.btn-readAgain {
  background: #ffffff;
  color: #434343;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}
.sign-img {
  margin-top: 64px;
}
/* Popup Styles */
.MuiDialogContent-root:first-child {
  background-color: #47b39c !important;
  padding-top: 16px !important;
}

.popup-text {
  font-size: 16px !important;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  padding-bottom: 10px;
}

.btn-popup-close {
  background: none;
  border: none;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  padding-bottom: 10px;
}

.task-note {
  height: auto;
}
.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 1rem;
  padding: 0;
}
.task-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.task-head .filters {
  display: flex;
  align-items: center;
}

/* Tasks & Notes tables Styles */
.task-today {
  padding: 32px 32px 0px 32px !important;
}
.task-overdue {
  padding: 18px 32px 0 32px !important;
}
.task-upcomming {
  padding: 18px 32px 120px 32px !important;
}
.tasks-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.tasks-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-tasks {
  width: 100%;
}

.table-tasks thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-tasks tr th:not(:first-child),
.table-tasks tr td:not(:first-child) {
  text-align: left;
}
.table-tasks tr th:first-child,
.table-tasks tr td:first-child {
  width: 345px;
  max-width: 345px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.table-tasks tr td:first-child {
  font-size: 14px !important;
}
.table-tasks td,
.table-tasks th {
  vertical-align: middle !important;
}
.table-tasks th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-tasks td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-tasks tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-tasks tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-tasks td button {
  padding: 13px 30px;
  background: #434343;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.task-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.task-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

#tasks-title-field {
  width: 100%;
  font-size: 24px;
  border: none;
  margin-bottom: 16px;
}
#tasks-title-field:focus-visible {
  width: 100%;
  font-size: 24px;
  border: none;
  outline: none;
}
#tasks-details-field {
  width: 100%;
  font-size: 16px;
  border: none;
  margin-top: 13px;
}
#tasks-details-field:focus-visible {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}

.select-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.select-menus .priority,
.select-menus .assigned,
.select-menus .department {
  width: auto;
}

.form-toggle-label {
  font-size: 14px;
}

/* Datepicker Styles */
.datepicker-footer-plugin {
  padding: 16px 24px;
}
.footer-plugin-devider {
  margin: 0px 8px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  padding: 0;
}
.task-footer-head p {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.days-input {
  padding: 16px 0 0 0;
}
.days-input span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #37394c;
}
.days-input input {
  width: 94px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
  padding: 8px 16px;
  border: 1px solid #434343;
  margin: 0 16px 0 20px;
}
.days-input input[type='number']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.days-input input[type='number'] {
  -moz-appearance: textfield;
}
.days-input input[type='number']:focus-visible {
  outline: none;
  border: 1px solid #434343;
}

.task-repeat-box {
  border: 1px solid RGBA(172, 180, 184, 0.2);
}
.weekDay-select .weekDay-list {
  padding: 14px 0;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #37394c;
}
.recursion-toggle {
  padding: 24px 0;
}
.task-footer-btns {
  text-align: right;
}
.task-footer-btns .repeat-save {
  padding: 9px 24px;
  border: 2px solid #434343;
  background-color: #434343;
  outline: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  margin-right: 16px;
}
.task-footer-btns .repeat-cancle {
  padding: 9px 24px;
  border: 2px solid #434343;
  background-color: #ffffff;
  outline: none;
  border-radius: 4px;
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.start-date-box,
.end-date-box {
  height: 30px;
  width: 100px;
  border: 1px solid #e9e9e9;
}
.datepicker-footer-plugin .start-date-title,
.datepicker-footer-plugin .end-date-title {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.start-date-box span,
.end-date-box span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  display: flex;
  padding: 4px 0 0 6px;
}

/* DetailsPage */
.details-info {
  height: auto;
}

.details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 16px;
}

.details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.details-filters .due-date-info,
.details-filters .department-info,
.details-filters .priority-info,
.details-filters .assigned-info {
  display: flex;
  align-items: center;
  padding-right: 24px;
}
.details-filters .due-date-info p,
.details-filters .department-info p,
.details-filters .priority-info p,
.details-filters .assigned-info p {
  font-size: 14px;
}

.details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding-top: 27px;
  padding-bottom: 27px;
}

/* Comment Section */
.comment-section {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}
.show-comment {
  padding-bottom: 260px;
}
.comment-section img {
  height: 30px;
  border-radius: 50%;
  margin-right: 16px;
}
.comment-section .commenter-name {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.comment-section .comment-date {
  padding-left: 32px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}
.comment-section .comment-text-box {
  width: 630px;
  max-width: 630px;
}
.comment-section .comment-text-box .comment-text {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  max-width: 630px;
  padding: 16px 46px;
}

.activity-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.task-comment-input {
  width: 100%;
}
#comment-input {
  width: 100%;
  margin-right: 8px;
}

.editor-clickBox {
  width: 100%;
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.click-box {
  height: 30px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
}
.editor-clickBox:hover {
  cursor: text;
}
.activity-section .show-activity {
  padding-bottom: 266px;
}
.activity-section .activity-head {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-bottom: 24px;
}
.activity-section .activity-list .activity-name {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.activity-section .activity-list .activity-time {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  text-align: right;
}
.activity-section .show-more-btn {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #9b9b9b;
}
.activity-section .comment-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.activity-section .activity-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0.5rem 0;
  padding: 0;
}
/* TinyMCE style */
.tox .tox-tbtn--select {
  position: absolute !important;
  right: 2px !important;
  padding: 9px 17px !important;
  width: auto !important;
  background: #434343 !important;
  color: #ffffff !important;
  font-weight: 700 !important;
}
.tox-tinymce {
  border: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar-overlord:first-child
  .tox-toolbar__primary,
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar:first-child {
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: #ffffff !important;
  background-color: #ffffff !important;
}

.checklist-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.head-progress {
  min-width: 500px;
}
.progress {
  width: 400px !important;
  height: 16px !important;
  font-size: 16px !important;
  background-color: #efefef !important;
  border-radius: 12px !important;
}
.progress-bar {
  background-color: #282828 !important;
  transition: width 0.6s ease;
}
.completed-checklist {
  padding-right: 16px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #282828;
}
.remaining-checklist {
  padding-left: 16px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #282828;
}
.content-section {
  margin-top: 14px;
}

.show-check-list {
  width: 560px;
  max-width: 560px;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 29px 32px;
  margin-right: 22px;
}
.show-check-details {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 24px;
}

/* Checklist Table */
.table-title {
  color: #434343;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 36px;
}

.checklist-table tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.checklist-table tr td:first-child {
  padding: 13px;
  margin-bottom: 1px;
  background: #434343;
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  width: 40px;
  min-width: 40px;
}
.checklist-table tr td:nth-child(2) {
  width: 330px;
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px !important;
  font-weight: 500;
  padding: 0px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.checklist-table tr td:nth-child(2):hover {
  background: lightgrey;
  cursor: pointer;
}
.checklist-table td {
  vertical-align: middle !important;
  /* padding: 13px 17px !important; */
}

.checklist-table td button {
  padding: 13px 30px;
  background: #434343;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

/* Checklist Details  */
.details-index {
  width: 40px;
  height: 40px;
  background: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 30px;
}

.details-index p {
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.details-title {
  color: #434343;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding: 0px 48px 16px 16px;
}
.details-description {
  color: #434343;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 33px 48px 33px 16px;
}
.details-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

/* Comment Section */
.checklist-comment-section {
  padding-bottom: 24px;
  position: relative;
}
.show-checklist-comment {
  padding-bottom: 120px;
}
.checklist-comment-head {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-top: 24px;
  padding-bottom: 24px;
}
.checklist-comment-section img {
  height: 30px;
  border-radius: 50%;
  margin-right: 16px;
}
.checklist-comment-section .checklist-commenter-name {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.checklist-comment-section .checklist-comment-date {
  padding-left: 32px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}
.checklist-comment-text-box .checklist-comment-text {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  min-width: 100%;
  padding: 16px 16px 16px 46px;
}

.activity-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.comment-input-box {
  width: 100%;
  min-width: 536px !important;
  height: auto;
  margin-left: 16px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
  display: flex;
  align-items: center;
}
.editor-area {
  width: 100%;
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.checklist-comment-input {
  width: 100%;
}
.editor-clickBox {
  width: 100%;
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.click-box {
  height: 30px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
}
.editor-clickBox:hover {
  cursor: text;
}

.checklist-comment-input {
  width: 100%;
}

.activity-section .show-activity {
  padding-bottom: 120px;
}
.activity-section .activity-head {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding: 24px 0;
}
.activity-section .activity-list .activity-name {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.activity-section .activity-list .activity-time {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  text-align: right;
}
.activity-section .show-more-btn {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #9b9b9b;
}
.activity-section .comment-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.activity-section .activity-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0.5rem 0;
  padding: 0;
}

.tox .tox-tbtn--select {
  position: absolute !important;
  right: 2px !important;
  padding: 9px 17px !important;
  width: auto !important;
  background: #434343 !important;
  color: #ffffff !important;
  font-weight: 700 !important;
}

.tox-tinymce {
  border: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar-overlord:first-child
  .tox-toolbar__primary,
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar:first-child {
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: #ffffff !important;
  background-color: #ffffff !important;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.requests-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.requests-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* requests tables Styles */
.frontDesk-requests {
  padding: 32px 32px 0px 32px !important;
}
.maintenance-requests {
  padding: 18px 32px 120px 32px !important;
}
.requests-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.requests-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-requests {
  width: 100%;
  /* table-layout: fixed;
  word-wrap: break-word; */
}

.table-requests thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-requests tr th:not(:last-child),
.table-requests tr td:not(:last-child) {
  text-align: left;
}
.table-requests tr th:first-child,
.table-requests tr td:first-child {
  width: 378px;
  max-width: 378px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-requests tr td:first-child {
  font-size: 14px !important;
}
.table-requests td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 6px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-requests tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-requests tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-requests th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-requests td,
.table-requests th {
  vertical-align: middle !important;
}

.table-requests .td-status p {
  padding: 8px 12px !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.table-requests td button {
  padding: 13px 0px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: 126px;
}
.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.short-tables tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.request-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.request-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

#requests-title-field {
  width: 100%;
  font-size: 24px;
  border: none;
  margin-bottom: 16px;
}
#requests-title-field:focus-visible {
  width: 100%;
  font-size: 24px;
  border: none;
  outline: none;
}
#requests-room-field {
  width: 100%;
  font-size: 20px;
  border: none;
  margin: 16px auto;
}
#requests-room-field:focus-visible {
  width: 100%;
  font-size: 20px;
  border: none;
  outline: none;
}
#requests-details-field {
  width: 100%;
  font-size: 16px;
  border: none;
  margin-top: 13px;
}
#requests-details-field:focus-visible {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}
.select-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.select-menus .due-date,
.select-menus .priority,
.select-menus .assigned,
.select-menus .department {
  width: auto;
}
.form-toggle-label {
  font-size: 14px;
}

/* Datepicker Plugin Style */
.datepicker-footer-plugin {
  padding: 16px 24px;
}
.footer-plugin-devider {
  margin: 0px 8px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  padding: 0;
}

.start-date-box,
.end-date-box {
  height: 30px;
  width: 100px;
  border: 1px solid #e9e9e9;
}
.datepicker-footer-plugin .start-date-title,
.datepicker-footer-plugin .end-date-title {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.start-date-box span,
.end-date-box span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  display: flex;
  padding: 4px 0 0 6px;
}

/* DetailsPage */
.btn-mark {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #bdbdbd;
  border: none;
}

.details-info {
  height: auto;
}

.details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 16px;
}

.details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 8px;
}
.details-filters .start-date-info,
.details-filters .due-date-info,
.details-filters .assigned-info,
.details-filters .priority-info {
  display: flex;
  align-items: center;
  padding-right: 24px;
}
.details-filters .start-date-info p,
.details-filters .due-date-info p,
.details-filters .assigned-info p,
.details-filters .priority-info p {
  font-size: 14px;
}

.details-status p {
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
}
.details-info .detailsRoom {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-top: 16px;
  padding-bottom: 16px;
}

.details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  margin-top: 13px;
  padding-top: 27px;
  padding-bottom: 27px;
}

.top-navs {
  margin: 40px 40px auto 40px;
}
.head-navs {
  height: auto;
}
.profile {
  width: 20%;
  height: auto;
  justify-content: space-evenly;
  align-items: center;
}
.profile .user-name {
  color: #434343;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.profile img {
  height: 43px;
  border-radius: 50%;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.maintenance-req-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.maintenance-req-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* requests tables Styles */
.today-requests {
  padding: 32px 32px 0px 32px !important;
}
.overdue-requests {
  padding: 18px 32px 0px 32px !important;
}
.upcomming-requests {
  padding: 18px 32px 120px 32px !important;
}
.maintenance-requests-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.requests-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-maintenance-requests {
  width: 100%;
}

.table-maintenance-requests thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-maintenance-requests tr th:not(:last-child),
.table-maintenance-requests tr td:not(:last-child) {
  text-align: left;
}
.table-maintenance-requests tr th:first-child,
.table-maintenance-requests tr td:first-child {
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-maintenance-requests tr td:first-child {
  font-size: 14px !important;
}
.table-maintenance-requests td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 6px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-maintenance-requests tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-maintenance-requests tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-maintenance-requests th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-maintenance-requests td,
.table-maintenance-requests th {
  vertical-align: middle !important;
}

.table-maintenance-requests .td-status p {
  padding: 8px 12px !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.table-maintenance-requests td button {
  padding: 13px 0px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: 126px;
}
.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.short-tables tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.request-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.request-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

#requests-title-field {
  width: 100%;
  font-size: 24px;
  border: none;
  margin-bottom: 16px;
}
#requests-title-field:focus-visible {
  width: 100%;
  font-size: 24px;
  border: none;
  outline: none;
}
#requests-room-field {
  width: 100%;
  font-size: 20px;
  border: none;
  margin: 16px auto;
}
#requests-room-field:focus-visible {
  width: 100%;
  font-size: 20px;
  border: none;
  outline: none;
}
#requests-details-field {
  width: 100%;
  font-size: 16px;
  border: none;
  margin-top: 13px;
}
#requests-details-field:focus-visible {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}
.select-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.select-menus .due-date,
.select-menus .priority,
.select-menus .assigned,
.select-menus .department {
  width: auto;
}

.form-toggle-label {
  font-size: 14px;
}

/* DetailsPage */
.btn-mark {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #bdbdbd;
  border: none;
}

.details-info {
  height: auto;
}

.details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 16px;
}

.details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 8px;
}

.details-filters .due-date-info,
.details-filters .assigned-info,
.details-filters .priority-info {
  display: flex;
  align-items: center;
  padding-right: 24px;
}
.details-filters .due-date-info p,
.details-filters .assigned-info p,
.details-filters .priority-info p {
  font-size: 14px;
}

.details-status p {
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
}
.details-info .detailsRoom {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-top: 16px;
  padding-bottom: 16px;
}

.details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  margin-top: 13px;
  padding-top: 27px;
  padding-bottom: 27px;
}

.addExpense .addExpense-btn {
  border: none;
  outline: none;
  background: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}
.addExpense .addExpense-btn:hover {
  cursor: pointer;
}
.addExpense .addExpense-text {
  font-size: 13px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-left: 16px;
}

.details-checkList {
  padding: 24px 16px;
}
.details-checkList p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 22px;
  color: #282828;
}
.details-checkList ul {
  list-style: none;
  padding-left: 0;
}
.details-checkList ul li {
  align-items: center;
  margin-bottom: 16px;
}
.details-checkList ul li label {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
  padding-left: 16px;
  margin-bottom: 0;
}
.details-checkList ul li label:hover {
  cursor: pointer;
}
.details-checkList ul li input[type='checkbox']:checked:after {
  content: '\25FC';
  color: rgb(0, 0, 0);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -56%);
  transform: translate(-50%, -56%);
}

.expense-table {
  margin-top: 24px;
}
.expense-table-head {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 18px;
}

.table-expenseItem {
  width: 100%;
  margin-bottom: 8px;
}

.table-expenseItem tr th:first-child,
.table-expenseItem tr td:first-child {
  text-align: left;
  width: 8px;
}
.table-expenseItem tr th:last-child,
.table-expenseItem tr td:last-child {
  text-align: right;
}
.table-expenseItem tr th:nth-child(3),
.table-expenseItem tr td:nth-child(3),
.table-expenseItem tr th:nth-child(4),
.table-expenseItem tr td:nth-child(4) {
  text-align: right;
}

.table-expenseItem tr th {
  padding-bottom: 16px;
}
.table-expenseItem tr td {
  padding-bottom: 16px;
}
.table-expenseItem tr th {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}
.table-expenseItem tr td {
  color: #434343;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}
.table-expenseItem .total-row {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}
.table-expenseItem .total-row td {
  color: #434343;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding-top: 16px;
}

/* Drawer Styles */
.drawer-wraper {
  height: auto;
  width: 460px;
  margin: 0 auto;
}

.drawer-head {
  margin-bottom: 30px;
}
.drawer-head p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
}

.drawer-head .private-mark .drawer-toggle-label {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}

.drawer-form .item p {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 4px;
}
.drawer-form .item input {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding: 10px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  margin-bottom: 32px;
}
.drawer-form .item input[type='number']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.drawer-form .item input[type='number'] {
  -moz-appearance: textfield;
}
.drawer-form .item input:focus-visible {
  outline: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
}
.drawer-form .item input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.drawer-form .item input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.drawer-form .item input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}

.drawer-form .item-file input[type='file'] {
  display: none;
}
.custom-file-upload {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  display: inline-block;
  padding: 32px 0;
}
.custom-file-upload span {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
  padding-left: 6px;
}

.drawer-btns {
  width: 100%;
  margin: 40px auto;
}
.drawer-btns .drawer-cancle {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  margin-right: 32px;
}
.drawer-btns .drawer-save {
  border: 1px solid #434343;
  border-radius: 4px;
  outline: none;
  padding: 13px 24px;
  background: #434343;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.replacements-req-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.replacements-req-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* requests tables Styles */
.table-today-replacement {
  padding: 32px 32px 0px 32px !important;
}
.table-upcoming-replacement {
  padding: 18px 32px 120px 32px !important;
}
.replacement-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.replacement-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-replacement {
  width: 100%;
}

.table-replacement thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-replacement tr th:first-child,
.table-replacement tr td:first-child {
  width: 360px;
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-replacement tr td:first-child {
  font-size: 14px !important;
}
.table-replacement td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-replacement tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-replacement tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-replacement th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-replacement td,
.table-replacement th {
  vertical-align: middle !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Drawer Styles */
.drawer-wraper {
  height: auto;
  width: 460px;
  margin: 0 auto;
}

.drawer-head {
  margin-bottom: 30px;
}
.drawer-head p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
}

.drawer-head .private-mark .drawer-toggle-label {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}

.drawer-form .item p {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 4px;
}
.drawer-form .readOnly p {
  color: #bdbdbd;
}

.drawer-form .item input {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding: 10px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  margin-bottom: 32px;
}
.drawer-form .readOnly input {
  color: #bdbdbd;
}
.drawer-form .item input[type='number']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.drawer-form .item input[type='number'] {
  -moz-appearance: textfield;
}
.drawer-form .item input:focus-visible {
  outline: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
}
.drawer-form .item input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.drawer-form .item input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.drawer-form .item input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}

.drawer-form .item-file input[type='file'] {
  display: none;
}
.custom-file-upload {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  display: inline-block;
  padding: 32px 0;
}
.custom-file-upload span {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
  padding-left: 6px;
}
.drawer-delete {
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #de6552;
}

.drawer-btns {
  width: 100%;
  margin: 40px auto;
}
.drawer-btns .drawer-cancle {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  margin-right: 32px;
}
.drawer-btns .drawer-save {
  border: 1px solid #434343;
  border-radius: 4px;
  outline: none;
  padding: 13px 24px;
  background: #434343;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.preventive-maintenance-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.preventive-maintenance-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* Preventive Maintenance tables Styles */
.today-maintenance {
  padding: 32px 32px 0px 32px !important;
}
.overdue-maintenance {
  padding: 18px 32px 0px 32px !important;
}
.upcomming-maintenance {
  padding: 18px 32px 120px 32px !important;
}
.preventive-maintenance-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.preventive-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-preventive {
  width: 100%;
}

.table-preventive thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-preventive tr th:not(:last-child),
.table-preventive tr td:not(:last-child) {
  text-align: left;
}
.table-preventive tr th:first-child,
.table-preventive tr td:first-child {
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-preventive tr td:first-child {
  font-size: 14px !important;
}
.table-preventive td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 6px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-preventive tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-preventive tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-preventive th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-preventive td,
.table-preventive th {
  vertical-align: middle !important;
}

.table-preventive .td-status p {
  padding: 8px 12px !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.table-preventive td button {
  padding: 13px 0px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: 126px;
}
.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.short-tables tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.preM-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.preM-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

#preM-title-field {
  width: 100%;
  font-size: 24px;
  border: none;
  margin-bottom: 16px;
}
#preM-title-field:focus-visible {
  width: 100%;
  font-size: 24px;
  border: none;
  outline: none;
}
#preM-room-field {
  width: 100%;
  font-size: 20px;
  border: none;
  margin: 16px auto;
}
#preM-room-field:focus-visible {
  width: 100%;
  font-size: 20px;
  border: none;
  outline: none;
}
#preM-details-field {
  width: 100%;
  font-size: 16px;
  border: none;
  margin-top: 13px;
}
#preM-details-field:focus-visible {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}
.select-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
}
.select-menus .due-date,
.select-menus .priority,
.select-menus .assigned,
.select-menus .department {
  width: auto;
}
.form-toggle-label {
  font-size: 14px;
}
.form-checklist-head {
  align-items: center;
  padding-bottom: 24px;
}
.form-checklist-btn {
  border: none;
  outline: none;
  background: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.form-checklist-addBox {
  align-items: center;
  padding-bottom: 16px;
}
.form-checklist-addBox .checklist-custom-squre {
  height: 16px;
  width: 16px;
  border: 2px solid #000000;
}

.form-checklist-addBox input[type='text'] {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
  padding-left: 15px;
}
/* Datepicker Styles */
.footer-plugin {
  padding: 16px;
}
.start-date-box,
.end-date-box {
  height: 30px;
  width: 100px;
  border: 1px solid #e9e9e9;
}
.footer-plugin .start-date-title,
.footer-plugin .end-date-title {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.start-date-box span,
.end-date-box span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  display: flex;
  padding: 4px 0 0 6px;
}
.footer-plugin .datePicker-select {
  padding: 8px 16px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
}
.footer-plugin .quick-select {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 16px;
  /* border-top: 1px solid RGBA(172, 180, 184, 0.2); */
}

/* DetailsPage */
.btn-mark {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #bdbdbd;
  border: none;
}

.details-info {
  height: auto;
}

.details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 16px;
}

.details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 8px;
}

.details-filters .due-date-info,
.details-filters .assigned-info,
.details-filters .priority-info {
  display: flex;
  align-items: center;
  padding-right: 24px;
}
.details-filters .due-date-info p,
.details-filters .assigned-info p,
.details-filters .priority-info p {
  font-size: 14px;
}

.details-status p {
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
}
.details-info .detailsRoom {
  font-size: 20px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-top: 16px;
  padding-bottom: 16px;
}

.details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  margin-top: 13px;
  padding-top: 27px;
  padding-bottom: 27px;
}

.addExpense .addExpense-btn {
  border: none;
  outline: none;
  background: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}
.addExpense .addExpense-btn:hover {
  cursor: pointer;
}
.addExpense .addExpense-text {
  font-size: 13px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-left: 16px;
}

.details-checkList {
  padding: 24px 16px;
}
.details-checkList p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 22px;
  color: #282828;
}
.details-checkList ul {
  list-style: none;
  padding-left: 0;
}
.details-checkList ul li {
  align-items: center;
  margin-bottom: 16px;
}
.details-checkList ul li label {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
  padding-left: 16px;
  margin-bottom: 0;
}
.details-checkList ul li label:hover {
  cursor: pointer;
}

.details-checkList ul li input[type='checkbox']:checked:after {
  content: '\25FC';
  color: rgb(0, 0, 0);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -56%);
  transform: translate(-50%, -56%);
}

.expense-table {
  margin-top: 24px;
}
.expense-table-head {
  padding-bottom: 18px;
}
.expense-table-head .expense-table-title {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.table-expenseItem {
  width: 100%;
  margin-bottom: 8px;
}

.table-expenseItem tr th:first-child,
.table-expenseItem tr td:first-child {
  text-align: left;
  width: 8px;
}
.table-expenseItem tr th:last-child,
.table-expenseItem tr td:last-child {
  text-align: right;
}
.table-expenseItem tr th:nth-child(3),
.table-expenseItem tr td:nth-child(3),
.table-expenseItem tr th:nth-child(4),
.table-expenseItem tr td:nth-child(4) {
  text-align: right;
}

.table-expenseItem tr th {
  padding-bottom: 16px;
}
.table-expenseItem tr td {
  padding-bottom: 16px;
}
.table-expenseItem tr th {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}
.table-expenseItem tr td {
  color: #434343;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
}
.table-expenseItem .total-row {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}
.table-expenseItem .total-row td {
  color: #434343;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding-top: 16px;
}

/* Drawer Styles */
.drawer-wraper {
  height: auto;
  width: 460px;
  margin: 0 auto;
}

.drawer-head {
  margin-bottom: 30px;
}
.drawer-head p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
}

.drawer-head .private-mark .drawer-toggle-label {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}

.drawer-form .item p {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 4px;
}
.drawer-form .item input {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding: 10px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  margin-bottom: 32px;
}
.drawer-form .item input[type='number']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.drawer-form .item input[type='number'] {
  -moz-appearance: textfield;
}
.drawer-form .item input:focus-visible {
  outline: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
}
.drawer-form .item input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.drawer-form .item input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.drawer-form .item input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}

.drawer-form .item-file input[type='file'] {
  display: none;
}
.custom-file-upload {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  display: inline-block;
  padding: 32px 0;
}
.custom-file-upload span {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
  padding-left: 6px;
}

.drawer-btns {
  width: 100%;
  margin: 40px auto;
}
.drawer-btns .drawer-cancle {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  margin-right: 32px;
}
.drawer-btns .drawer-save {
  border: 1px solid #434343;
  border-radius: 4px;
  outline: none;
  padding: 13px 24px;
  background: #434343;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
}

.maintenance-checklist-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.mChacklist-head-progress {
  min-width: 500px;
}
.progress {
  width: 400px !important;
  height: 16px !important;
  font-size: 16px !important;
  background-color: #efefef !important;
  border-radius: 12px !important;
}
.progress-bar {
  background-color: #282828 !important;
  transition: width 0.6s ease;
}
.completed-checklist {
  padding-right: 16px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #282828;
}
.remaining-checklist {
  padding-left: 16px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #282828;
}
.content-section {
  margin-top: 14px;
}

.show-check-list {
  width: 560px;
  max-width: 560px;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 29px 32px;
  margin-right: 22px;
}
.show-check-details {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 24px;
}

/* Checklist Table */
.table-title {
  color: #434343;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 36px;
}

.maintenance-checklist-table tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.maintenance-checklist-table tr td:first-child {
  padding: 13px;
  margin-bottom: 1px;
  background: #434343;
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  width: 40px;
  min-width: 40px;
}
.maintenance-checklist-table tr td:nth-child(2) {
  width: 330px;
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px !important;
  font-weight: 500;
  padding: 0px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.maintenance-checklist-table tr td:nth-child(2):hover {
  background: lightgrey;
  cursor: pointer;
}
.maintenance-checklist-table td {
  vertical-align: middle !important;
  /* padding: 13px 17px !important; */
}

.maintenance-checklist-table td button {
  padding: 13px 30px;
  background: #434343;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

/* Checklist Details  */
.details-index {
  width: 40px;
  height: 40px;
  background: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 30px;
}

.details-index p {
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.details-title {
  color: #434343;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding: 0px 48px 16px 16px;
}
.details-description {
  color: #434343;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 33px 48px 33px 16px;
}
.details-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

/* Comment Section */
.checklist-comment-section {
  padding-bottom: 24px;
  position: relative;
}
.show-checklist-comment {
  padding-bottom: 120px;
}
.checklist-comment-head {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-top: 24px;
  padding-bottom: 24px;
}
.checklist-comment-section img {
  height: 30px;
  border-radius: 50%;
  margin-right: 16px;
}
.checklist-comment-section .checklist-commenter-name {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.checklist-comment-section .checklist-comment-date {
  padding-left: 32px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}
.checklist-comment-text-box .checklist-comment-text {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  min-width: 100%;
  padding: 16px 16px 16px 46px;
}

.activity-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.comment-input-box {
  width: 100%;
  min-width: 536px !important;
  height: auto;
  margin-left: 16px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
  display: flex;
  align-items: center;
}
.editor-area {
  width: 100%;
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.checklist-comment-input {
  width: 100%;
}
.editor-clickBox {
  width: 100%;
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.click-box {
  height: 30px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
}
.editor-clickBox:hover {
  cursor: text;
}

.checklist-comment-input {
  width: 100%;
}
.activity-section .show-activity {
  padding-bottom: 120px;
}
.activity-section .activity-head {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding: 24px 0;
}
.activity-section .activity-list .activity-name {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.activity-section .activity-list .activity-time {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  text-align: right;
}
.activity-section .show-more-btn {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #9b9b9b;
}
.activity-section .comment-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.activity-section .activity-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0.5rem 0;
  padding: 0;
}

.tox .tox-tbtn--select {
  position: absolute !important;
  right: 2px !important;
  padding: 9px 17px !important;
  width: auto !important;
  background: #434343 !important;
  color: #ffffff !important;
  font-weight: 700 !important;
}

.tox-tinymce {
  border: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar-overlord:first-child
  .tox-toolbar__primary,
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar:first-child {
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: #ffffff !important;
  background-color: #ffffff !important;
}

.hk-overview {
  height: auto;
}
.hk-overview-statistics {
  width: -webkit-max-content;
  width: max-content;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: none !important;
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0.25rem;
}
.hk-overview-card {
  padding: 0 !important;
}
.hk-overview-cardBody {
  padding: 0 32px !important;
}

.hk-overview-card img {
  height: 24px;
  margin-top: 22px;
}
.hk-overview-card .value {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #434343;
  font-weight: 600;
}
.hk-overview-card .state-name {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #bdbdbd;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.hk-overview-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 30px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.hk-overview-head .filters {
  display: flex;
  align-items: center;
}

/* Housekeeping table Styles */
.hk-overview-table {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.hk-overview-table-wrap {
  padding: 32px !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-hk-overview {
  width: 100%;
}

.table-hk-overview thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-hk-overview tr th:first-child,
.table-hk-overview tr td:first-child {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-hk-overview tr td:first-child {
  font-size: 14px !important;
}
.table-hk-overview td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 10px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-hk-overview tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
/* .table-hk-overview tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
} */
.table-hk-overview th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-hk-overview td,
.table-hk-overview th {
  vertical-align: middle !important;
}

.table-hk-overview .td-status p {
  padding: 8px 12px !important;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

.operations {
  height: auto;
}
.cashCount-statistics {
  width: -webkit-max-content;
  width: max-content;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: none !important;
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0.25rem;
}
.cashCount-card {
  padding: 0 !important;
}
.cashCount-cardBody {
  padding: 0 32px !important;
}

.cashCount-card img {
  height: 24px;
  margin-top: 22px;
}
.cashCount-card .value {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #434343;
  font-weight: 600;
}
.cashCount-card .state-name {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #bdbdbd;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.cashCount-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}

.cashCount-head .filters {
  display: flex;
  align-items: center;
}

/* CashCount Table Style */
.cashCount-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.cashCount-table-wrap {
  padding: 32px !important;
}
.table-cashCount {
  width: 100%;
}

.table-cashCount thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

/* .table-cashCount tr th:first-child, */
.table-cashCount tr td:first-child {
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-cashCount tr th:first-child {
  padding-left: 17px;
  padding-right: 40px;
}
.table-cashCount tr td:first-child {
  font-size: 14px !important;
  padding-right: 40px;
}

.table-cashCount tr td:last-child {
  padding: 13px 6px;
}
.table-cashCount td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 14px;
  color: #434343;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-cashCount tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-cashCount th {
  border-top: none !important;
  padding: 0px 8px 10px 16px;
}
.table-cashCount td,
.table-cashCount th {
  vertical-align: middle !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}
/* Add Cash Count Modal Style */
.addNew-form {
  padding: 48px;
}
.addNew-form .form-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding-bottom: 24px;
}

.addNew-form .cash-input-group .cash-input-box .cash-input-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: RGB(121, 123, 136);
}
.addNew-form .cash-input-group .cash-input-box input {
  height: 80px;
  width: 80px;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: RGB(244, 244, 244);
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #434343;
}

.addNew-form .cash-input-group .cash-input-box input::-webkit-outer-spin-button,
.addNew-form
  .cash-input-group
  .cash-input-box
  input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.addNew-form .cash-input-group .cash-input-box input[type='number'] {
  -moz-appearance: textfield;
}
.addNew-form .note-input-box .note-input-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: RGB(121, 123, 136);
  padding-bottom: 16px;
}
.addNew-form .note-input-box textarea {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: RGB(244, 244, 244);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: RGB(67, 67, 67);
  padding: 16px;
  resize: none;
}

.addNew-form .dropAmount-input-box .dropAmount-input-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: RGB(121, 123, 136);
  padding-bottom: 16px;
}
.addNew-form .dropAmount-input-box input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: RGB(244, 244, 244);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: RGB(67, 67, 67);
  padding: 16px;
}
.addNew-form .dropAmount-input-box input::-webkit-outer-spin-button,
.addNew-form .dropAmount-input-box input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.addNew-form .dropAmount-input-box input[type='number'] {
  -moz-appearance: textfield;
}
.addNew-form .addCashCount-btns {
  margin-top: 48px;
}
.addNew-form .addCashCount-btns .cashCount-submit {
  padding: 16px 145px;
  background-color: RGB(67, 67, 67);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 4px;
  margin-bottom: 32px;
}
.addNew-form .addCashCount-btns .cashCount-cancle {
  background: none;
  color: RGB(155, 155, 155);
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.employee-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* employees tables Styles */
.employee-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.frontDesk-employees {
  padding: 32px 32px 0px 32px !important;
}
.housekeeping-employees {
  padding: 28px 32px 120px 32px !important;
}

.employees-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.table-employees {
  width: 100%;
}
.table-employees thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-employees tr th,
.table-employees tr td {
  text-align: left;
  vertical-align: middle !important;
}
.table-employees tr th:first-child,
.table-employees tr td:first-child {
  width: 430px;
  max-width: 430px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-employees tr td:first-child {
  font-size: 14px !important;
}
.table-employees tr td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
/* .table-employees tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
} */
/* .table-employees tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
} */
.table-employees th {
  border-top: none !important;
  padding: 28px 17px 10px 17px;
}

.table-employees td button {
  color: #de6552;
  font-size: 14px;
  border: none;
  background: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.short-employees-table tbody tr td {
  padding: 13px 17px !important;
}
/* .short-employees-table tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
} */
.short-employees-table tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.request-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.employee-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.btn-save-add {
  background: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  color: #ffffff;
  padding: 13px 24px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.btn-save-close {
  background: #ffffff;
  color: #3b3b3b;
  border: 2px solid #3b3b3b;
  border-radius: 4px;
  padding: 13px 24px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  margin-left: 24px;
}

.clear-btn {
  background: none;
  border: none;
  outline: none;
  color: #de6552;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  padding: 0;
  margin-right: 24px;
}

.form-wrap {
  padding-bottom: 24px;
}
.form-wrap .form-index {
  color: #bdbdbd;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.form-wrap form {
  margin: 24px 0px;
}
.form-wrap .top-input-row {
  padding-bottom: 40px;
}
.form-wrap .inputs-row .input-fileds {
  width: 176px;
}
.form-wrap .inputs-row .input-fileds label {
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  padding-bottom: 4px;
  display: block;
}
.form-wrap .inputs-row .input-fileds input {
  width: 100%;
  padding: 10px 0;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-wrap .inputs-row .input-fileds input::-webkit-input-placeholder, .form-wrap .custom-inputs .user-name-field input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.form-wrap .inputs-row .input-fileds input:-ms-input-placeholder, .form-wrap .custom-inputs .user-name-field input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.form-wrap .inputs-row .input-fileds input::placeholder,
.form-wrap .custom-inputs .user-name-field input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}

.form-wrap .custom-inputs .user-name-field input {
  border: none;
}

/* Add Department Modal Style */
.addDept-form {
  padding: 40px;
}
.addDept-form .form-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.addDept-form .department-input-box {
  padding: 40px 0;
}
.addDept-form .department-input-box .department-input-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: RGB(67, 67, 67);
  padding-bottom: 4px;
}
.addDept-form .department-input-box input {
  width: 380px;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  color: #434343;
}
.addDept-form .department-input-box input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.addDept-form .department-input-box input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.addDept-form .department-input-box input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}

.deptAdd-submit {
  background: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  color: #ffffff;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.deptAdd-cancle {
  background: #ffffff;
  color: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  margin-left: 24px;
}

.main-devider-departmentsMV {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.departmentsMV-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

/* departments tables Styles */
.table-departmentsMV {
  padding: 0px 40px 32px 32px !important;
  margin-top: 32px;
  position: relative;
}
.table-departmentsMV:after {
  content: '';
  height: 96%;
  min-height: 47vh;
  width: 1px;
  position: absolute;
  right: 0;
  top: 0;
  margin-bottom: 32px;
  background-color: RGBA(172, 180, 184, 0.2);
}
.table-suggestionsMV {
  padding: 32px 32px 32px 40px !important;
}

.departmentsMV-tables {
  width: auto;
  min-height: 57vh;
  height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.dual-table-MV {
  display: flex;
  height: auto;
}
.departmentsMV-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
  color: #434343;
}
.departments-title {
  padding-bottom: 40px;
}
.suggestions-title {
  padding-bottom: 16px;
}
.suggestions-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #9b9b9b;
  padding-bottom: 24px;
  margin-left: 16px;
}

.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-deptMV {
  width: 100%;
}

.table-deptMV thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-deptMV tr th:not(:last-child),
.table-deptMV tr td:not(:last-child) {
  text-align: left;
}
.table-deptMV tr th:first-child,
.table-deptMV tr td:first-child {
  width: 215px;
  min-width: 180px;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-deptMV tr td:first-child {
  font-size: 14px !important;
}
.table-deptMV td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 12px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-deptMV tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-deptMV th {
  border-top: none !important;
  padding: 0px 17px 0px 17px;
}
.table-deptMV td,
.table-deptMV th {
  vertical-align: middle !important;
}

.table-deptMV td .edit-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #434343;
}
.table-deptMV td .delete-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #de6552;
}
.table-deptMV td .addDept-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.departmentsMV-half-header {
  margin-bottom: 40px;
}
.table-departmentsMV-half {
  padding: 0px 40px 32px 32px !important;
  margin-top: 32px;
}
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.deptForm-wrap label {
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  padding-bottom: 4px;
  display: block;
}
.deptForm-wrap input {
  max-width: auto;
  min-width: 180px;
  padding: 10px 0;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 32px;
}
.deptForm-wrap input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.deptForm-wrap input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.deptForm-wrap input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}

/* Add Department Modal Style */
.editDept-form {
  padding: 40px;
}
.editDept-form .form-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.editDept-form .department-input-box {
  padding: 40px 0;
}
.editDept-form .department-input-box .department-input-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #bdbdbd;
  padding-bottom: 4px;
}
.editDept-form .department-input-box input {
  width: 380px;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  color: #434343;
}
.editDept-form .department-input-box input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.editDept-form .department-input-box input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.editDept-form .department-input-box input::placeholder {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}

.editDept-submit {
  background: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  color: #ffffff;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.editDept-cancle {
  background: #ffffff;
  color: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  margin-left: 24px;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.checklistMV-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.checklistMV-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  -webkit-transform: translate(0, 24px) scale(1);
          transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* Preventive Maintenance tables Styles */
.frontDesk-MV {
  padding: 32px 32px 0px 32px !important;
}
.maintenance-MV {
  padding: 18px 32px 0px 32px !important;
}
.housekeeping-MV {
  padding: 18px 32px 120px 32px !important;
}
.checklistMV-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.checklistMV-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-checklistMV {
  width: 100%;
}

.table-checklistMV thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-checklistMV tr th:not(:last-child),
.table-checklistMV tr td:not(:last-child) {
  text-align: left;
}
.table-checklistMV td,
.table-checklistMV th {
  vertical-align: middle !important;
}
.table-checklistMV tr th:first-child,
.table-checklistMV tr td:first-child {
  width: 445px;
  max-width: 445px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-checklistMV tr td:first-child {
  font-size: 14px !important;
}
.table-checklistMV td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-checklistMV tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-checklistMV tr td:first-child:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-checklistMV th {
  border-top: none !important;
  padding: 40px 17px 10px 17px;
}

.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.short-tables tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.checklistMV-details-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 40px 24px;
}

/* DetailsPage */
.btn-mark {
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 13px 24px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #bdbdbd;
  border: none;
}

.checklistMV-details .details-info {
  height: auto;
}

.checklistMV-details .details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin: 18px 16px;
}

.checklistMV-details .details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
}

.checklistMV-details .details-filters .work-day-info,
.checklistMV-details .details-filters .assigned-info,
.checklistMV-details .details-filters .reminder-info,
.checklistMV-details .details-filters .shift-info {
  display: flex;
  align-items: center;
  min-width: 125px;
  padding-right: 24px;
}
.checklistMV-details .details-filters .work-day-info p,
.checklistMV-details .details-filters .assigned-info p,
.checklistMV-details .details-filters .reminder-info p,
.checklistMV-details .details-filters .shift-info p {
  font-size: 14px;
}

.checklistMV-details .details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 30px 16px;
}

.addExpense .addExpense-btn {
  border: none;
  outline: none;
  background: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}
.addExpense .addExpense-btn:hover {
  cursor: pointer;
}
.addExpense .addExpense-text {
  font-size: 13px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-left: 16px;
}

/* Drawer Styles */
.drawer-wraper {
  height: auto;
  margin: 0 auto;
}
.checklistMV-form-devider {
  margin: 40px auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}
.checklistMV-add-department {
  margin: auto 16px;
}
.checklistMV-add-items {
  margin: auto 16px;
}
.checklistMV-add-department .form-dept-head {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-bottom: 40px;
}

.checklistMV-add-items .form-items-head {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-bottom: 32px;
}
.checklistMV-input-label {
  color: #bdbdbd;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 4px;
}

.checklistMV-add-items .input-item {
  margin-bottom: 24px;
}

.checklistMV-add-items input {
  width: 100%;
  padding: 10px 0px;
  outline: none;
  background: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #434343;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.checklistMV-add-items .add-items-task input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.checklistMV-add-items .add-items-task input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.checklistMV-add-items .add-items-task input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.checklistMV-add-items .add-item-desc textarea {
  width: 100%;
  padding: 10px 0px;
  outline: none;
  background: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #434343;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  resize: none;
}
.checklistMV-add-items .add-item-desc textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.checklistMV-add-items .add-item-desc textarea:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}
.checklistMV-add-items .add-item-desc textarea::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}

.input-item-dropdown {
  margin-bottom: 24px;
  width: 200px;
}

.drawer-btns {
  width: 100%;
  margin: 40px auto;
  display: flex;
}
.drawer-btns .drawer-cancle {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  margin-right: 32px;
}
.drawer-btns .drawer-save {
  border: 1px solid #434343;
  border-radius: 4px;
  outline: none;
  padding: 13px 24px;
  background: #434343;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
}

.checklistMV-checkbox {
  -webkit-appearance: initial;
  appearance: initial;
  width: 15px;
  height: 15px;
  border: none;
  background: rgb(255, 255, 255);
  position: relative;
  border: 2px solid #3b3b3b;
}
.checklistMV-checkbox:checked {
  background: rgb(255, 255, 255);
}
.checklistMV-checkbox:checked:after {
  content: '\25FC';
  color: #3b3b3b;
  position: absolute;
  left: 44%;
  top: 50%;
  -webkit-transform: translate(-50%, -54%);
  transform: translate(-50%, -54%);
}

.checklistMV-checkbox:hover {
  cursor: pointer;
}

.efficiencyMV-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 29px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}

.efficiencyMV-form {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}

/* Table input style */
.HK-table-input {
  padding: 40px 32px;
}

.HK-table-input .HK-table-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
  padding-bottom: 16px;
}
.HK-table-input .HK-table-subTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #686868;
  margin-left: 16px;
}

.table-input-HK {
  margin-top: 40px;
}
.table-input-HK thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-input-HK tr th,
.table-input-HK tr td {
  width: 215px;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-input-HK tr td:first-child {
  font-size: 14px !important;
}
.table-input-HK td {
  font-weight: 500;
  padding: 12px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-input-HK tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-input-HK th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}

.table-input-HK tr td input {
  border: none;
  outline: none;
  background: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
}
.table-input-HK tr td input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.table-input-HK tr td input[type='number'] {
  -moz-appearance: textfield;
}
.table-input-HK tr td input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
}
.table-input-HK tr td input:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
}
.table-input-HK tr td input::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
}

/* Other input style */
.efficiencyMV-input-wrap {
  padding: 0 32px 40px 32px;
  width: 400px;
}
.efficiencyMV-input-wrap .input-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
  padding-bottom: 16px;
}
.efficiencyMV-input-wrap .input-subTitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #686868;
  margin-left: 16px;
  padding-bottom: 16px;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field {
  margin-left: 16px;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field label {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #bdbdbd;
  margin: 0;
  padding-bottom: 4px;
}
/* .efficiencyMV-input-wrap .efficiencyMV-input-field input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #434343;
  outline: none;
  background: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
  padding: 10px 0;
} */
.efficiencyMV-input-wrap
  .efficiencyMV-input-field
  input[type='number']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field input[type='number'] {
  -moz-appearance: textfield;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #434343;
  outline: none;
  background: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
  padding: 10px 0;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field input::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field input:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
}
.efficiencyMV-input-wrap .efficiencyMV-input-field input::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #bdbdbd;
}

.page-form-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0px 24px 40px 24px;
  padding: 0;
}

.efficiencyMV-input-wrap .input-title .toggle-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}

.efficiencyMV-form .efficiencyMV-save-btn {
  margin-left: 48px;
  padding-bottom: 32px;
}

.efficiencyMV-form .efficiencyMV-save-btn button {
  border: 1px solid #434343;
  background-color: #434343;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  padding: 13px 24px;
}

/* Wrapping Minutes */
.minutes-wrap {
  position: relative;
}
.suffix-minute {
  position: absolute;
  left: 0;
  top: 8px;
  color: #5bb69d;
  margin-left: 24px;
  font: inherit;
}
.table-suffix-minute {
  position: absolute;
  left: 0;
  top: 0;
  color: #5bb69d;
  margin-left: 24px;
  font: inherit;
}

.main-devider-roomType {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.room-type-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

/* RoomType tables Styles */
.table-roomTypeMV {
  padding: 0px 40px 32px 32px !important;
  margin-top: 32px;
  position: relative;
}
.table-roomTypeMV:after {
  content: '';
  height: 96%;
  min-height: 47vh;
  width: 1px;
  position: absolute;
  right: 0;
  top: 0;
  margin-bottom: 32px;
  background-color: RGBA(172, 180, 184, 0.2);
}
.table-suggestionsMV {
  padding: 32px 32px 32px 40px !important;
}

.roomType-tables {
  width: auto;
  min-height: 57vh;
  height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.dual-table-MV {
  display: flex;
  height: auto;
}
.roomType-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-left: 16px;
  color: #434343;
}
.roomType-title {
  padding-bottom: 40px;
}
.suggestions-title {
  padding-bottom: 16px;
}
.suggestions-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #9b9b9b;
  padding-bottom: 24px;
  margin-left: 16px;
}

.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-RoomTypes {
  width: 100%;
}

.table-RoomTypes thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-RoomTypes tr th:not(:last-child),
.table-RoomTypes tr td:not(:last-child) {
  text-align: left;
}
.table-RoomTypes tr th:first-child,
.table-RoomTypes tr td:first-child {
  width: 215px;
  min-width: 180px;
  max-width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-RoomTypes tr td:first-child {
  font-size: 14px !important;
}
.table-RoomTypes td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 12px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-RoomTypes tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-RoomTypes th {
  border-top: none !important;
  padding: 0px 17px 0px 17px;
}
.table-RoomTypes td,
.table-RoomTypes th {
  vertical-align: middle !important;
}

.table-RoomTypes td .edit-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #434343;
}
.table-RoomTypes td .delete-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #de6552;
}
.table-RoomTypes td .addRoomType-btn {
  background: none;
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #47b39c;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.roomType-half-header {
  margin-bottom: 40px;
}
.table-roomTypeMV-half {
  padding: 0px 40px 32px 32px !important;
  margin-top: 32px;
}
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.roomTypeForm-wrap label {
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  padding-bottom: 4px;
  display: block;
}
.roomTypeForm-wrap input {
  max-width: auto;
  min-width: 180px;
  padding: 10px 0;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 32px;
}
.roomTypeForm-wrap input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.roomTypeForm-wrap input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.roomTypeForm-wrap input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}

/* Add Department Modal Style */
.editRoomType-form {
  padding: 40px;
}
.editRoomType-form .form-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.editRoomType-form .roomType-input-box {
  padding: 40px 0;
}
.editRoomType-form .roomType-input-box .roomType-input-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #bdbdbd;
  padding-bottom: 4px;
}
.editRoomType-form .roomType-input-box input {
  width: 380px;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  color: #434343;
}
.editRoomType-form .roomType-input-box input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.editRoomType-form .roomType-input-box input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.editRoomType-form .roomType-input-box input::placeholder {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}
.editRoom-submit {
  background: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  color: #ffffff;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.editRoom-cancle {
  background: #ffffff;
  color: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  margin-left: 24px;
}

.sign-up {
  width: 1100px;
  height: 800px;
  background-color: #ffffff;
  margin: 60px auto;
  box-shadow: 0 15px 30px -10px rgb(172 180 184 / 20%) !important;
}
.signUp-img-section {
  position: relative;
  height: 100%;
  width: 473px;
  background: #3b3b3b;
  padding: 40px 32px;
}
.signUp-img-section .app-text {
  width: 258px;
}
.signUp-img-section .app-text .brand-title {
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}
.signUp-img-section .app-text .brand-subTitle {
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  padding-top: 32px;
}

.signUp-img-section img {
  width: 318px;
  height: 346px;
  display: flex;
  margin: 100px auto;
}

/* SignUp form style */
.signUp-form-section {
  width: 380px;
  margin: 100px auto;
}
.signUp-form-title {
  font-family: 'Open sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #434343;
  padding-bottom: 48px;
}

.signUp-input-box {
  padding-bottom: 18px;
}
.signUp-input-box label {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #434343;
  margin-bottom: 3px;
  display: block;
}
.signUp-input-box input[type='text'],
.signUp-input-box input[type='email'],
.signUp-input-box input[type='password'] {
  width: 100%;
  padding: 10px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  outline: none;
  background: none;
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #434343;
}
.signUp-input-box input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(175, 175, 180);
}
.signUp-input-box input:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(175, 175, 180);
}
.signUp-input-box input::placeholder {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(175, 175, 180);
}

/* Checkbox style */
.signUp-check-box label {
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #434343;
  margin-bottom: 0;
  padding-left: 16px;
}
.signUp-check-box label:hover {
  cursor: pointer !important;
}
.signUp-check-box input[type='checkbox']:checked {
  background: rgb(255, 255, 255);
}
.signUp-check-box input[type='checkbox']:checked:after {
  content: '\25FC';
  color: rgb(0, 0, 0);
  position: absolute;
  left: 50% !important;
  top: 45% !important;
  -webkit-transform: translate(-48%, -52%) !important;
  transform: translate(-48%, -52%) !important;
}

.signUp-input-box .error-msg {
  display: block;
  color: #de6552;
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
}

/* SignUp button style */
.signUp-btn {
  width: 100%;
  padding: 12px 130px;
  background-color: #434343;
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border: 1px solid #434343;
  outline: none;
  margin: 32px auto;
}

/* Password popUp style */
.pass-check {
  width: 200px;
  height: 250;
  padding: 32px 24px;
}

.pass-msg p {
  font-family: 'Open sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  padding-left: 16px;
}
.pass-msg {
  margin-bottom: 8px;
}

.signUp-success {
  width: 1100px;
  height: 800px;
  background-color: #ffffff;
  margin: 60px auto;
  box-shadow: 0 15px 30px -10px rgb(172 180 184 / 20%) !important;
  text-align: center;
}
.signUp-success img {
  height: 345px;
  margin-top: 74px;
}
.signUp-success .success-title {
  color: #434343;
  font-family: 'Open sans', sans-serif;
  font-size: 40px;
  font-weight: 700;
  padding-top: 48px;
  padding-bottom: 20px;
}

.signUp-success .success-text {
  color: #434343;
  font-family: 'Open sans', sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.signUp-success .start-btn {
  padding: 12px 130px;
  background-color: #434343;
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border: 1px solid #434343;
  outline: none;
  margin: 48px auto;
}

/* Login Page */
.forgot-text {
  font-family: 'Open sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #434343;
  padding-top: 40px;
}

.hotel-info {
  width: auto;
  position: absolute;
  right: 32px;
  bottom: 40px;
  text-align: right;
}

.hotel-info .hotel-text {
  font-family: 'Open sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  padding-bottom: 6px;
}
.hotel-info .hotel-name {
  font-family: 'Open sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
}

