.task-note {
  height: auto;
}
.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 1rem;
  padding: 0;
}
.task-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.task-head .filters {
  display: flex;
  align-items: center;
}

/* Tasks & Notes tables Styles */
.task-today {
  padding: 32px 32px 0px 32px !important;
}
.task-overdue {
  padding: 18px 32px 0 32px !important;
}
.task-upcomming {
  padding: 18px 32px 120px 32px !important;
}
.tasks-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.tasks-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-tasks {
  width: 100%;
}

.table-tasks thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-tasks tr th:not(:first-child),
.table-tasks tr td:not(:first-child) {
  text-align: left;
}
.table-tasks tr th:first-child,
.table-tasks tr td:first-child {
  width: 345px;
  max-width: 345px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.table-tasks tr td:first-child {
  font-size: 14px !important;
}
.table-tasks td,
.table-tasks th {
  vertical-align: middle !important;
}
.table-tasks th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-tasks td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-tasks tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-tasks tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-tasks td button {
  padding: 13px 30px;
  background: #434343;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.short-tables tbody tr td {
  padding: 13px 17px !important;
}
.short-tables tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.task-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.task-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

#tasks-title-field {
  width: 100%;
  font-size: 24px;
  border: none;
  margin-bottom: 16px;
}
#tasks-title-field:focus-visible {
  width: 100%;
  font-size: 24px;
  border: none;
  outline: none;
}
#tasks-details-field {
  width: 100%;
  font-size: 16px;
  border: none;
  margin-top: 13px;
}
#tasks-details-field:focus-visible {
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}

.select-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.select-menus .priority,
.select-menus .assigned,
.select-menus .department {
  width: auto;
}

.form-toggle-label {
  font-size: 14px;
}

/* Datepicker Styles */
.datepicker-footer-plugin {
  padding: 16px 24px;
}
.footer-plugin-devider {
  margin: 0px 8px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  padding: 0;
}
.task-footer-head p {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.days-input {
  padding: 16px 0 0 0;
}
.days-input span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #37394c;
}
.days-input input {
  width: 94px;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
  padding: 8px 16px;
  border: 1px solid #434343;
  margin: 0 16px 0 20px;
}
.days-input input[type='number']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.days-input input[type='number'] {
  -moz-appearance: textfield;
}
.days-input input[type='number']:focus-visible {
  outline: none;
  border: 1px solid #434343;
}

.task-repeat-box {
  border: 1px solid RGBA(172, 180, 184, 0.2);
}
.weekDay-select .weekDay-list {
  padding: 14px 0;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #37394c;
}
.recursion-toggle {
  padding: 24px 0;
}
.task-footer-btns {
  text-align: right;
}
.task-footer-btns .repeat-save {
  padding: 9px 24px;
  border: 2px solid #434343;
  background-color: #434343;
  outline: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  margin-right: 16px;
}
.task-footer-btns .repeat-cancle {
  padding: 9px 24px;
  border: 2px solid #434343;
  background-color: #ffffff;
  outline: none;
  border-radius: 4px;
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.start-date-box,
.end-date-box {
  height: 30px;
  width: 100px;
  border: 1px solid #e9e9e9;
}
.datepicker-footer-plugin .start-date-title,
.datepicker-footer-plugin .end-date-title {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.start-date-box span,
.end-date-box span {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  display: flex;
  padding: 4px 0 0 6px;
}

/* DetailsPage */
.details-info {
  height: auto;
}

.details-info .details-Title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 16px;
}

.details-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.details-filters .due-date-info,
.details-filters .department-info,
.details-filters .priority-info,
.details-filters .assigned-info {
  display: flex;
  align-items: center;
  padding-right: 24px;
}
.details-filters .due-date-info p,
.details-filters .department-info p,
.details-filters .priority-info p,
.details-filters .assigned-info p {
  font-size: 14px;
}

.details-info .detailsText {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding-top: 27px;
  padding-bottom: 27px;
}

/* Comment Section */
.comment-section {
  padding-top: 24px;
  padding-bottom: 24px;
  position: relative;
}
.show-comment {
  padding-bottom: 260px;
}
.comment-section img {
  height: 30px;
  border-radius: 50%;
  margin-right: 16px;
}
.comment-section .commenter-name {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.comment-section .comment-date {
  padding-left: 32px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}
.comment-section .comment-text-box {
  width: 630px;
  max-width: 630px;
}
.comment-section .comment-text-box .comment-text {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  max-width: 630px;
  padding: 16px 46px;
}

.activity-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.task-comment-input {
  width: 100%;
}
#comment-input {
  width: 100%;
  margin-right: 8px;
}

.editor-clickBox {
  width: 100%;
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.click-box {
  height: 30px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
}
.editor-clickBox:hover {
  cursor: text;
}
.activity-section .show-activity {
  padding-bottom: 266px;
}
.activity-section .activity-head {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding-bottom: 24px;
}
.activity-section .activity-list .activity-name {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.activity-section .activity-list .activity-time {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  text-align: right;
}
.activity-section .show-more-btn {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #9b9b9b;
}
.activity-section .comment-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.activity-section .activity-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0.5rem 0;
  padding: 0;
}
/* TinyMCE style */
.tox .tox-tbtn--select {
  position: absolute !important;
  right: 2px !important;
  padding: 9px 17px !important;
  width: auto !important;
  background: #434343 !important;
  color: #ffffff !important;
  font-weight: 700 !important;
}
.tox-tinymce {
  border: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar-overlord:first-child
  .tox-toolbar__primary,
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar:first-child {
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: #ffffff !important;
  background-color: #ffffff !important;
}
