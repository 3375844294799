.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.employee-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* employees tables Styles */
.employee-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.frontDesk-employees {
  padding: 32px 32px 0px 32px !important;
}
.housekeeping-employees {
  padding: 28px 32px 120px 32px !important;
}

.employees-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.table-employees {
  width: 100%;
}
.table-employees thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-employees tr th,
.table-employees tr td {
  text-align: left;
  vertical-align: middle !important;
}
.table-employees tr th:first-child,
.table-employees tr td:first-child {
  width: 430px;
  max-width: 430px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-employees tr td:first-child {
  font-size: 14px !important;
}
.table-employees tr td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
/* .table-employees tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
} */
/* .table-employees tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
} */
.table-employees th {
  border-top: none !important;
  padding: 28px 17px 10px 17px;
}

.table-employees td button {
  color: #de6552;
  font-size: 14px;
  border: none;
  background: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.short-employees-table tbody tr td {
  padding: 13px 17px !important;
}
/* .short-employees-table tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
} */
.short-employees-table tr td:first-child {
  width: 275px !important;
  max-width: 275px !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Form and Half Table Styles */
.half-tables {
  width: 35%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.forms-section {
  width: 65%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 40px;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #434343 !important;
}
.request-half-header .MuiButton-root {
  color: #ffffff !important;
  padding: 13px 24px !important;
  background: #434343 !important;
  font-size: 12px;
  font-weight: 700;
  box-sizing: border-box;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.employee-half-header {
  margin-bottom: 32px;
}

.form-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.btn-save-add {
  background: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  color: #ffffff;
  padding: 13px 24px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.btn-save-close {
  background: #ffffff;
  color: #3b3b3b;
  border: 2px solid #3b3b3b;
  border-radius: 4px;
  padding: 13px 24px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  margin-left: 24px;
}

.clear-btn {
  background: none;
  border: none;
  outline: none;
  color: #de6552;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  padding: 0;
  margin-right: 24px;
}

.form-wrap {
  padding-bottom: 24px;
}
.form-wrap .form-index {
  color: #bdbdbd;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.form-wrap form {
  margin: 24px 0px;
}
.form-wrap .top-input-row {
  padding-bottom: 40px;
}
.form-wrap .inputs-row .input-fileds {
  width: 176px;
}
.form-wrap .inputs-row .input-fileds label {
  color: #434343;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  padding-bottom: 4px;
  display: block;
}
.form-wrap .inputs-row .input-fileds input {
  width: 100%;
  padding: 10px 0;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  color: #434343;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-wrap .inputs-row .input-fileds input::placeholder,
.form-wrap .custom-inputs .user-name-field input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}

.form-wrap .custom-inputs .user-name-field input {
  border: none;
}

/* Add Department Modal Style */
.addDept-form {
  padding: 40px;
}
.addDept-form .form-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.addDept-form .department-input-box {
  padding: 40px 0;
}
.addDept-form .department-input-box .department-input-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: RGB(67, 67, 67);
  padding-bottom: 4px;
}
.addDept-form .department-input-box input {
  width: 380px;
  background: none;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  color: #434343;
}
.addDept-form .department-input-box input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: RGB(189, 189, 189);
}

.deptAdd-submit {
  background: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  color: #ffffff;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
}
.deptAdd-cancle {
  background: #ffffff;
  color: #434343;
  border: 2px solid #434343;
  border-radius: 4px;
  padding: 13px 24px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open sans', sans-serif;
  text-transform: capitalize !important;
  margin-left: 24px;
}
