.overview {
  height: auto;
}

.overview-title {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 24px 32px !important;
  margin-bottom: 24px !important;
}
.overview-title p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.statistic {
  width: 100%;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  padding: 0.5rem !important;
  border: none !important;
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0.25rem;
}

.statistic-card img {
  height: 24px;
}
.statistic-card .value {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #434343;
  font-weight: 600;
}
.statistic-card .state-name {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #bdbdbd;
}

/* Table styles */
.comparison-section,
.our-rate-section,
.we-offer-section,
.onLeave-section,
.event-section {
  padding: 24px 32px;
}
.table-top {
  padding-bottom: 24px;
  margin: 0 12px;
}

.event-section,
.onLeave-section,
.comparison-section {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.our-rate-section,
.we-offer-section {
  width: 100%;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.dash-table-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table {
  width: 100%;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}
.table td {
  font-size: 14px !important;
  font-weight: 500;
}

.table th {
  border-top: none !important;
}
.table td {
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table td,
.table th {
  padding: 0.75 0.75rem !important;
  vertical-align: top;
}
.table td .other-price {
  padding-right: 0.15rem;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}
