.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.replacements-req-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}
.main-title-short {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding: 4px 38px !important;
}

.replacements-req-head .filters {
  display: flex;
  align-items: center;
}

.close-normal {
  border: none;
  color: #434343;
  background: none;
  font-size: 14px;
  padding: 0px;
  font-weight: 600;
}

.short-devider {
  margin-top: 30px;
  margin-bottom: 0;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

.MuiSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 20px !important;
  position: relative !important;
  pointer-events: none;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}
.MuiInputLabel-formControl {
  position: relative !important;
}
.MuiInputBase-root {
  align-items: unset !important;
}

.MuiFormLabel-root {
  color: #434343 !important;
  padding: 0;
  font-size: 12px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600 !important;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.toggle-label {
  color: #434343;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: relative;
  transform: translate(0, 24px) scale(1);
}
.MuiFormControlLabel-root {
  margin-top: 10px;
  margin-right: 40px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

/* requests tables Styles */
.table-today-replacement {
  padding: 32px 32px 0px 32px !important;
}
.table-upcoming-replacement {
  padding: 18px 32px 120px 32px !important;
}
.replacement-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}

.replacement-table-head {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
}
.aria-expand-btn:hover {
  cursor: pointer !important;
}
.table-top span {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.table-replacement {
  width: 100%;
}

.table-replacement thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

.table-replacement tr th:first-child,
.table-replacement tr td:first-child {
  width: 360px;
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-replacement tr td:first-child {
  font-size: 14px !important;
}
.table-replacement td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-replacement tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-replacement tbody tr:hover {
  background: lightgrey;
  cursor: pointer;
}
.table-replacement th {
  border-top: none !important;
  padding: 0px 17px 10px 17px;
}
.table-replacement td,
.table-replacement th {
  vertical-align: middle !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}

/* Drawer Styles */
.drawer-wraper {
  height: auto;
  width: 460px;
  margin: 0 auto;
}

.drawer-head {
  margin-bottom: 30px;
}
.drawer-head p {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #282828;
}

.drawer-head .private-mark .drawer-toggle-label {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}

.drawer-form .item p {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  margin-bottom: 4px;
}
.drawer-form .readOnly p {
  color: #bdbdbd;
}

.drawer-form .item input {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding: 10px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  margin-bottom: 32px;
}
.drawer-form .readOnly input {
  color: #bdbdbd;
}
.drawer-form .item input[type='number']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.drawer-form .item input[type='number'] {
  -moz-appearance: textfield;
}
.drawer-form .item input:focus-visible {
  outline: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
}
.drawer-form .item input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
}

.drawer-form .item-file input[type='file'] {
  display: none;
}
.custom-file-upload {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2);
  display: inline-block;
  padding: 32px 0;
}
.custom-file-upload span {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #bdbdbd;
  padding-left: 6px;
}
.drawer-delete {
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #de6552;
}

.drawer-btns {
  width: 100%;
  margin: 40px auto;
}
.drawer-btns .drawer-cancle {
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  margin-right: 32px;
}
.drawer-btns .drawer-save {
  border: 1px solid #434343;
  border-radius: 4px;
  outline: none;
  padding: 13px 24px;
  background: #434343;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
}
