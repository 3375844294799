@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;700;800&display=swap');
.App {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Open Sans', sans-serif;
}
.pages {
  height: auto;
  width: 100%;
  margin-left: 8.5rem !important;
  background: #fafafa !important;
}
.main-section {
  margin: 40px;
}

hr {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* React Datepicker Styles */
.react-datepicker {
  border: 1px solid #e9e9e9 !important;
}
.react-datepicker__month-container {
  float: unset !important;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #e9e9e9 !important;
}
.react-datepicker__input-container input {
  margin-left: 8px;
  margin-right: 12px;
  width: 94px;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 14px;
}
.react-datepicker__input-container input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #161616;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.1rem !important;
  background-color: #bdbdbd !important;
  color: #000000 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.1rem !important;
  background-color: #3b3b3b !important;
  color: #fff;
}

.react-datepicker__year-text--in-selecting-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__day--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.1rem !important;
  background-color: #3b3b3b !important;
  color: #fff;
}

.react-datepicker__day--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--selected {
  border-radius: 0.1rem !important;
  background-color: #3b3b3b !important;
  color: #fff !important;
}

/* dropDown Check style */

.dropDown-check {
  -webkit-appearance: initial;
  appearance: initial;
  width: 15px;
  height: 15px;
  border: none;
  background: rgb(255, 255, 255);
  position: relative;
  border: 2px solid #3b3b3b;
}
.dropDown-check:checked {
  background: rgb(255, 255, 255);
}
.dropDown-check:checked:after {
  content: '\25FC';
  color: #3b3b3b;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -56%);
  -moz-transform: translate(-50%, -56%);
  -ms-transform: translate(-50%, -56%);
  transform: translate(-50%, -56%);
}

.dropDown-check:hover {
  cursor: pointer;
}
