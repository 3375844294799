@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;500;700;800&display=swap');
.sidebar {
  width: 145px;
  height: 100vh;
  position: fixed;
  z-index: 1;
  background: #fafafa;
}
.sidebar ul {
  margin-top: 2.5rem;
  list-style: none;
}
.sidebar li {
  margin: 1rem;
}

.brand-name {
  position: absolute;
  left: 49%;
  right: auto;
  bottom: 35px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  font-family: 'Alegreya Sans SC', sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #d3d3d3;
}
.setting {
  width: 100%;
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 35px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
