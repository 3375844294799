.top-navs {
  margin: 40px 40px auto 40px;
}
.head-navs {
  height: auto;
}
.profile {
  width: 20%;
  height: auto;
  justify-content: space-evenly;
  align-items: center;
}
.profile .user-name {
  color: #434343;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.profile img {
  height: 43px;
  border-radius: 50%;
}
