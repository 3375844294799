.operations {
  height: auto;
}
.cashCount-statistics {
  width: max-content;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: none !important;
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0.25rem;
}
.cashCount-card {
  padding: 0 !important;
}
.cashCount-cardBody {
  padding: 0 32px !important;
}

.cashCount-card img {
  height: 24px;
  margin-top: 22px;
}
.cashCount-card .value {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  color: #434343;
  font-weight: 600;
}
.cashCount-card .state-name {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #bdbdbd;
}

.main-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0 24px;
  padding: 0;
}
.cashCount-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 0px 32px !important;
}
.main-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  margin-left: 16px;
}

.cashCount-head .filters {
  display: flex;
  align-items: center;
}

/* CashCount Table Style */
.cashCount-tables {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
}
.cashCount-table-wrap {
  padding: 32px !important;
}
.table-cashCount {
  width: 100%;
}

.table-cashCount thead th {
  vertical-align: bottom;
  border-bottom: none !important;
  font-size: 10px !important;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  color: #797b88;
}

/* .table-cashCount tr th:first-child, */
.table-cashCount tr td:first-child {
  width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-cashCount tr th:first-child {
  padding-left: 17px;
  padding-right: 40px;
}
.table-cashCount tr td:first-child {
  font-size: 14px !important;
  padding-right: 40px;
}

.table-cashCount tr td:last-child {
  padding: 13px 6px;
}
.table-cashCount td {
  font-size: 12px !important;
  font-weight: 500;
  padding: 13px 14px;
  color: #434343;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-cashCount tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.table-cashCount th {
  border-top: none !important;
  padding: 0px 8px 10px 16px;
}
.table-cashCount td,
.table-cashCount th {
  vertical-align: middle !important;
}

.loading {
  font-size: 14px;
  font-weight: 600;
  color: rgb(255, 187, 0);
  text-align: center;
  margin: auto;
}
/* Add Cash Count Modal Style */
.addNew-form {
  padding: 48px;
}
.addNew-form .form-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #434343;
  padding-bottom: 24px;
}

.addNew-form .cash-input-group .cash-input-box .cash-input-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: RGB(121, 123, 136);
}
.addNew-form .cash-input-group .cash-input-box input {
  height: 80px;
  width: 80px;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: RGB(244, 244, 244);
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #434343;
}

.addNew-form .cash-input-group .cash-input-box input::-webkit-outer-spin-button,
.addNew-form
  .cash-input-group
  .cash-input-box
  input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.addNew-form .cash-input-group .cash-input-box input[type='number'] {
  -moz-appearance: textfield;
}
.addNew-form .note-input-box .note-input-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: RGB(121, 123, 136);
  padding-bottom: 16px;
}
.addNew-form .note-input-box textarea {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: RGB(244, 244, 244);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: RGB(67, 67, 67);
  padding: 16px;
  resize: none;
}

.addNew-form .dropAmount-input-box .dropAmount-input-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: RGB(121, 123, 136);
  padding-bottom: 16px;
}
.addNew-form .dropAmount-input-box input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: RGB(244, 244, 244);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: RGB(67, 67, 67);
  padding: 16px;
}
.addNew-form .dropAmount-input-box input::-webkit-outer-spin-button,
.addNew-form .dropAmount-input-box input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
.addNew-form .dropAmount-input-box input[type='number'] {
  -moz-appearance: textfield;
}
.addNew-form .addCashCount-btns {
  margin-top: 48px;
}
.addNew-form .addCashCount-btns .cashCount-submit {
  padding: 16px 145px;
  background-color: RGB(67, 67, 67);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 4px;
  margin-bottom: 32px;
}
.addNew-form .addCashCount-btns .cashCount-cancle {
  background: none;
  color: RGB(155, 155, 155);
  border: none;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
}
