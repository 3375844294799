.maintenance-checklist-head {
  background-color: #fff !important;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  border-radius: 0 !important;
  padding: 20px 32px !important;
}
.mChacklist-head-progress {
  min-width: 500px;
}
.progress {
  width: 400px !important;
  height: 16px !important;
  font-size: 16px !important;
  background-color: #efefef !important;
  border-radius: 12px !important;
}
.progress-bar {
  background-color: #282828 !important;
  transition: width 0.6s ease;
}
.completed-checklist {
  padding-right: 16px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #282828;
}
.remaining-checklist {
  padding-left: 16px;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #282828;
}
.content-section {
  margin-top: 14px;
}

.show-check-list {
  width: 560px;
  max-width: 560px;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 29px 32px;
  margin-right: 22px;
}
.show-check-details {
  width: auto;
  min-height: auto;
  background: #ffffff;
  box-shadow: 0 15px 30px -4px rgb(172 180 184 / 20%) !important;
  padding: 32px 24px;
}

/* Checklist Table */
.table-title {
  color: #434343;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 36px;
}

.maintenance-checklist-table tr:last-child {
  border-bottom: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.maintenance-checklist-table tr td:first-child {
  padding: 13px;
  margin-bottom: 1px;
  background: #434343;
  color: #ffffff;
  border: none;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  width: 40px;
  min-width: 40px;
}
.maintenance-checklist-table tr td:nth-child(2) {
  width: 330px;
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px !important;
  font-weight: 500;
  padding: 0px 17px;
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.maintenance-checklist-table tr td:nth-child(2):hover {
  background: lightgrey;
  cursor: pointer;
}
.maintenance-checklist-table td {
  vertical-align: middle !important;
  /* padding: 13px 17px !important; */
}

.maintenance-checklist-table td button {
  padding: 13px 30px;
  background: #434343;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

/* Checklist Details  */
.details-index {
  width: 40px;
  height: 40px;
  background: #434343;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-bottom: 30px;
}

.details-index p {
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.details-title {
  color: #434343;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding: 0px 48px 16px 16px;
}
.details-description {
  color: #434343;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 33px 48px 33px 16px;
}
.details-devider {
  margin: 0 auto;
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
}

/* Comment Section */
.checklist-comment-section {
  padding-bottom: 24px;
  position: relative;
}
.show-checklist-comment {
  padding-bottom: 120px;
}
.checklist-comment-head {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-top: 24px;
  padding-bottom: 24px;
}
.checklist-comment-section img {
  height: 30px;
  border-radius: 50%;
  margin-right: 16px;
}
.checklist-comment-section .checklist-commenter-name {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.checklist-comment-section .checklist-comment-date {
  padding-left: 32px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
}
.checklist-comment-text-box .checklist-comment-text {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  min-width: 100%;
  padding: 16px 16px 16px 46px;
}

.activity-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.comment-input-box {
  width: 100%;
  min-width: 536px !important;
  height: auto;
  margin-left: 16px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
  display: flex;
  align-items: center;
}
.editor-area {
  width: 100%;
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.checklist-comment-input {
  width: 100%;
}
.editor-clickBox {
  width: 100%;
  visibility: visible;
  transition: visibility 200ms ease-in-out;
}
.click-box {
  height: 30px;
  border: 1px solid RGBA(172, 180, 184, 0.2);
}
.editor-clickBox:hover {
  cursor: text;
}

.checklist-comment-input {
  width: 100%;
}
.activity-section .show-activity {
  padding-bottom: 120px;
}
.activity-section .activity-head {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
  padding: 24px 0;
}
.activity-section .activity-list .activity-name {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #434343;
}
.activity-section .activity-list .activity-time {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #9b9b9b;
  text-align: right;
}
.activity-section .show-more-btn {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #9b9b9b;
}
.activity-section .comment-btn {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: #434343;
}
.activity-section .activity-devider {
  border-top: 1px solid RGBA(172, 180, 184, 0.2);
  height: 0px;
  margin: 0.5rem 0;
  padding: 0;
}

.tox .tox-tbtn--select {
  position: absolute !important;
  right: 2px !important;
  padding: 9px 17px !important;
  width: auto !important;
  background: #434343 !important;
  color: #ffffff !important;
  font-weight: 700 !important;
}

.tox-tinymce {
  border: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar-overlord:first-child
  .tox-toolbar__primary,
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar:first-child {
  border-top: 1px solid RGBA(172, 180, 184, 0.2) !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: #ffffff !important;
  background-color: #ffffff !important;
}
